import React, { Suspense } from "react";
import Spinner from "../../spinner/Spinner";
import "./CardSection.css";

const Spline = React.lazy(() => import("@splinetool/react-spline"));

const CardSection = () => {
  return (
    <div className="card-section-box">
      <div className="card-gradient">
        <Spinner />
        <Suspense>
          <Spline
            scene={
              "https://prod.spline.design/vPOH3t9-cSivS5MY/scene.splinecode"
            }
            className="spline"
          />
        </Suspense>
      </div>
    </div>
  );
};

export default CardSection;
