import React, { useEffect, useState } from "react";
import "./Spinner.css"; // CSS for spinner styles

const Spinner = () => {
  const [hideLoading, setHideLoading] = useState(false);
  useEffect(() => {
    // Set a timer to hide the loading spinner after 3 seconds
    const timer = setTimeout(() => {
      setHideLoading(true);
    }, 2000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (hideLoading) {
    return <></>;
  }
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
